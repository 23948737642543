import React from "react";

import { Link } from 'gatsby';

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import featureStyle from "assets/jss/material-kit-react/views/landingPageSections/featureStyle.jsx";



class EditHere extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer >
          <GridItem >
            <div className={classes.featureWhite} >
              <div className={classes.featureLeftInfo}>
                <h3>Edit it HERE.</h3>
                <p>This will be a paragraph of stuff about Book Merlin</p>
                <Link to='/feature' className={classes.link}>
                  More Features...
                </Link>
              </div>
                <img 
                    src={require('pages/HomePage/img/features_editing.png')}
                    className={classes.featureRightImg}
                />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(featureStyle)(EditHere);
