import React from "react";

import { Link } from 'gatsby';

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import featureStyle from "assets/jss/material-kit-react/views/landingPageSections/featureStyle.jsx";



class WriteHere extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer >
          <GridItem >
            <div 
              className={classes.featureGrey} 
              //style={}
            >
                <img 
                    src={require('pages/HomePage/img/features_writing.png')}
                    className={classes.featureLeftImg}
                />
              <div className={classes.featureRightInfo}>
                <h3>Write it HERE.</h3>
                <p>This will be a paragraph of stuff about Book Merlin</p>
                <Link to='/feature' className={classes.link}>
                  More Features...
                </Link>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  } 
}

export default withStyles(featureStyle)(WriteHere);


